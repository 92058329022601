import React from 'react';

const AppleBlack = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 20 24" fill="none">
            <path d="M16.7239 12.83C16.7239 10.9499 17.7406 9.18036 19.4351 8.18501C18.4184 6.74728 16.7239 5.86253 14.9163 5.75193C12.9958 5.53074 11.1883 6.85788 10.1716 6.85788C9.15484 6.85788 7.68622 5.75193 6.10463 5.75193C3.95819 5.86253 2.0377 6.96847 1.02096 8.73798C-1.12548 12.3876 0.456111 17.9173 2.60255 20.9034C3.61928 22.3411 4.86196 24 6.44354 24C8.02513 23.8894 8.58998 23.0046 10.5105 23.0046C12.431 23.0046 12.8829 24 14.5774 24C16.272 24 17.2887 22.5623 18.3054 21.014C19.0962 20.0186 19.6611 18.8021 20 17.5855C17.9665 16.922 16.7239 14.9313 16.7239 12.83Z" fill="black" />
            <path d="M13.5605 3.8708C14.4643 2.76486 14.9162 1.43773 14.8032 0C13.4476 0.110594 12.0919 0.77416 11.1881 1.8801C10.2844 2.87545 9.8325 4.20259 9.94547 5.64031C11.4141 5.64031 12.6568 4.97675 13.5605 3.8708Z" fill="black" />
        </svg>
    );
};

export default AppleBlack;
import React from "react";

const GranteeSaftyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
    >
      <rect
        width="50"
        height="50"
        rx="25"
        fill="url(#paint0_linear_937_7097)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2281 35.0067C25.3388 35.0648 25.4627 35.0943 25.5865 35.0934C25.7103 35.0924 25.8331 35.0618 25.9449 35.0027L29.5128 33.0865C30.5245 32.5447 31.3168 31.9388 31.935 31.2334C33.279 29.6965 34.0129 27.735 33.9998 25.7124L33.9575 19.0408C33.9535 18.2723 33.4511 17.5866 32.7082 17.3374L26.0707 15.1C25.6711 14.9641 25.2331 14.967 24.8405 15.1069L18.2282 17.4241C17.4893 17.6832 16.996 18.3738 17 19.1432L17.0423 25.81C17.0554 27.8355 17.8145 29.7881 19.1806 31.3093C19.8048 32.0048 20.6041 32.6018 21.627 33.1348L25.2281 35.0067ZM24.2831 27.1652C24.4321 27.309 24.6254 27.38 24.8187 27.378C25.012 27.377 25.2043 27.3041 25.3513 27.1583L29.2504 23.2964C29.5434 23.0058 29.5403 22.5388 29.2444 22.2521C28.9474 21.9654 28.4692 21.9674 28.1762 22.258L24.8077 25.5939L23.4284 24.2619C23.1314 23.9752 22.6542 23.9782 22.3603 24.2688C22.0673 24.5594 22.0703 25.0264 22.3673 25.3131L24.2831 27.1652Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_937_7097"
          x1="49.0468"
          y1="6.15268"
          x2="0.953772"
          y2="43.8473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0008F" />
          <stop offset="1" stopColor="#0045E2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default GranteeSaftyIcon;

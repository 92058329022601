import About from "../components/About/About";

const AboutMain = () => {
  return (
    <div className="bg-white ">
      <About />
    </div>
  );
};

export default AboutMain;

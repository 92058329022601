import React from "react";

const DotBG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="142"
      height="142"
      viewBox="0 0 142 142"
      fill="none"
    >
      <rect
        opacity="0.203637"
        x="15.7983"
        y="16.4155"
        width="110.25"
        height="110.25"
        rx="16.3125"
        fill="#D1C4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.8405 1.70809C31.6492 1.12094 29.3971 2.42118 28.81 4.61244C28.223 6.80316 29.5231 9.05578 31.7143 9.64293C33.9056 10.2301 36.1578 8.92929 36.7448 6.73857C37.332 4.54731 36.0317 2.29523 33.8405 1.70809Z"
        fill="#D1C4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.6154 36.1373C22.4241 35.5501 20.172 36.8504 19.5849 39.0416C18.9979 41.2324 20.298 43.485 22.4892 44.0721C24.6805 44.6593 26.9327 43.3585 27.5197 41.1678C28.1069 38.9765 26.8066 36.7244 24.6154 36.1373Z"
        fill="#D1C4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.3903 70.566C13.199 69.9788 10.9469 71.2791 10.3598 73.4703C9.77264 75.6616 11.0729 77.9137 13.2641 78.5008C15.4554 79.088 17.7075 77.7877 18.2946 75.5965C18.8818 73.4052 17.5815 71.1531 15.3903 70.566Z"
        fill="#D1C4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.16469 104.995C3.97342 104.408 1.7212 105.709 1.1342 107.9C0.547052 110.091 1.84729 112.343 4.03855 112.93C6.22981 113.517 8.48189 112.217 9.06904 110.026C9.65604 107.835 8.35595 105.582 6.16469 104.995Z"
        fill="#D1C4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.2697 10.9337C66.0784 10.3465 63.8263 11.6468 63.2392 13.838C62.6522 16.0287 63.9523 18.2814 66.1435 18.8685C68.3348 19.4557 70.587 18.1549 71.174 15.9642C71.7612 13.7729 70.4609 11.5208 68.2697 10.9337Z"
        fill="#D1C4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.0441 45.3624C56.8528 44.7752 54.6007 46.0755 54.0136 48.2667C53.4266 50.4575 54.7267 52.7101 56.9179 53.2972C59.1092 53.8844 61.3614 52.5836 61.9484 50.3929C62.5356 48.2016 61.2353 45.9495 59.0441 45.3624Z"
        fill="#D1C4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.819 79.7916C47.6277 79.2044 45.3756 80.5047 44.7885 82.6959C44.2013 84.8872 45.5016 87.1393 47.6928 87.7264C49.8841 88.3136 52.1362 87.0133 52.7233 84.8221C53.3105 82.6308 52.0102 80.3787 49.819 79.7916Z"
        fill="#D1C4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.5939 114.221C38.4026 113.634 36.1504 114.934 35.5634 117.125C34.9763 119.316 36.2765 121.568 38.4678 122.156C40.659 122.743 42.9111 121.443 43.4982 119.251C44.0852 117.061 42.7851 114.808 40.5939 114.221Z"
        fill="#D1C4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.698 20.1588C100.507 19.5716 98.255 20.8719 97.6679 23.0631C97.0809 25.2538 98.381 27.5065 100.572 28.0936C102.764 28.6808 105.016 27.38 105.603 25.1893C106.19 22.998 104.89 20.7459 102.698 20.1588Z"
        fill="#D1C4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.4733 54.5875C91.282 54.0003 89.0299 55.3006 88.4428 57.4918C87.8558 59.6826 89.1559 61.9352 91.3471 62.5223C93.5384 63.1095 95.7906 61.8087 96.3776 59.618C96.9648 57.4267 95.6645 55.1746 93.4733 54.5875Z"
        fill="#D1C4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M84.2482 89.0172C82.0569 88.43 79.8048 89.7303 79.2177 91.9215C78.6305 94.1128 79.9308 96.3649 82.122 96.952C84.3133 97.5392 86.5654 96.2389 87.1525 94.0477C87.7397 91.8564 86.4394 89.6043 84.2482 89.0172Z"
        fill="#D1C4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.0226 123.446C72.8313 122.859 70.5791 124.16 69.9921 126.35C69.405 128.541 70.7052 130.794 72.8965 131.381C75.0877 131.968 77.3398 130.668 77.9269 128.476C78.5139 126.286 77.2139 124.033 75.0226 123.446Z"
        fill="#D1C4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M137.128 29.3839C134.936 28.7967 132.684 30.097 132.097 32.2882C131.51 34.4789 132.81 36.7316 135.001 37.3187C137.193 37.9059 139.445 36.6051 140.032 34.4144C140.619 32.2231 139.319 29.971 137.128 29.3839Z"
        fill="#D1C4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.902 63.8131C125.711 63.2259 123.459 64.5262 122.872 66.7174C122.285 68.9081 123.585 71.1608 125.776 71.7479C127.967 72.3351 130.219 71.0343 130.806 68.8436C131.393 66.6523 130.093 64.4002 127.902 63.8131Z"
        fill="#D1C4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M118.677 98.2423C116.486 97.6551 114.234 98.9554 113.646 101.147C113.059 103.338 114.359 105.59 116.551 106.177C118.742 106.764 120.994 105.464 121.581 103.273C122.168 101.081 120.868 98.8294 118.677 98.2423Z"
        fill="#D1C4E9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M109.452 132.671C107.261 132.084 105.008 133.385 104.421 135.575C103.834 137.767 105.134 140.019 107.326 140.606C109.517 141.193 111.769 139.893 112.356 137.701C112.943 135.511 111.643 133.258 109.452 132.671Z"
        fill="#D1C4E9"
      />
    </svg>
  );
};

export default DotBG;

import React from 'react';

const Customers = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
            <path d="M39.517 23.772C39.5133 23.7238 39.5121 23.6755 39.5071 23.6285C39.4663 21.7977 39.1472 20.3182 37.7073 19.7084C36.1857 19.0639 34.8447 19.0552 33.8613 19.4375C33.797 19.4634 33.6287 19.6849 33.5644 19.7257C33.4988 19.7665 33.1537 19.6515 33.0238 19.6824C30.8652 19.9904 30.1527 22.0241 30.3098 23.61C30.8256 24.5105 30.9889 25.6461 31.0371 26.8201C31.8412 28.2229 33.2217 29.3004 34.8695 29.3004C37.0702 29.3004 38.8132 27.4423 39.3562 25.4198C39.564 25.3963 39.736 25.2392 39.7521 25.0239L39.8201 24.2248C39.8374 24.0157 39.7063 23.8364 39.517 23.772ZM34.8658 28.6818C32.5946 28.6818 30.8961 26.2758 30.8961 24.1221C30.8961 24.0207 30.9048 23.9217 30.9122 23.8228C31.8585 23.4764 33.677 22.7527 33.8761 22.3717C34.7025 23.2302 37.6219 23.7127 38.9047 23.8747C38.9109 23.9564 38.9183 24.0392 38.9183 24.1221C38.9158 26.2313 37.1469 28.6818 34.8658 28.6818Z" fill="black" />
            <path d="M10.2684 24.2062L10.3365 25.0054C10.3562 25.2305 10.5418 25.3926 10.762 25.4049C11.2927 27.4324 13.006 29.2991 15.177 29.2991C16.8804 29.2991 18.3005 28.1833 19.112 26.7446C19.0947 25.7389 19.3594 24.706 19.9334 23.8512C19.8975 23.8252 19.8715 23.7869 19.8282 23.772C19.8245 23.7238 19.8233 23.6755 19.8184 23.6285C19.7775 21.7977 19.4584 20.3182 18.0185 19.7084C16.4969 19.0639 15.156 19.0552 14.1725 19.4375C14.1082 19.4634 13.9412 19.6849 13.8756 19.7257C13.8101 19.7665 13.4649 19.6515 13.335 19.6824C11.1764 19.9904 10.4639 22.0253 10.621 23.6112C10.616 23.6558 10.6136 23.7003 10.6098 23.7448C10.3983 23.7955 10.2486 23.9873 10.2684 24.2062ZM11.2209 23.824C12.1673 23.4776 13.9857 22.754 14.1836 22.3729C15.01 23.2315 17.9306 23.7139 19.2134 23.8759C19.2196 23.9576 19.227 24.0405 19.227 24.1234C19.227 26.2313 17.4581 28.6831 15.1782 28.6831C12.9058 28.6831 11.2086 26.277 11.2086 24.1234C11.2061 24.0207 11.2135 23.9217 11.2209 23.824Z" fill="black" />
            <path d="M21.8721 9.78734C22.195 11.0207 23.2366 12.1563 24.5578 12.1563C25.8975 12.1563 26.9551 11.0256 27.2879 9.796C27.4153 9.78115 27.5192 9.6859 27.5291 9.55478L27.5699 9.06862C27.5823 8.94121 27.4994 8.83235 27.3869 8.79276C27.3844 8.76431 27.3844 8.73462 27.3819 8.70617C27.3559 7.59283 27.1617 6.69351 26.2859 6.32116C25.3594 5.92902 24.5441 5.92283 23.9454 6.15663C23.9058 6.17271 23.8044 6.30755 23.7648 6.33229C23.7252 6.35703 23.5149 6.28776 23.4358 6.30632C22.1233 6.49311 21.6903 7.73015 21.7856 8.69627C21.7818 8.72225 21.7806 8.7507 21.7781 8.77792C21.6507 8.80884 21.5604 8.92512 21.5715 9.05872L21.6136 9.54488C21.6247 9.68219 21.7385 9.78115 21.8721 9.78734ZM22.1517 8.82492C22.7269 8.61463 23.8329 8.17424 23.9541 7.94168C24.4563 8.46371 26.2327 8.75812 27.0133 8.85585C27.017 8.90657 27.0219 8.95605 27.0219 9.00677C27.0219 10.2883 25.9445 11.779 24.5578 11.779C23.1747 11.779 22.1431 10.3156 22.1431 9.00677C22.1431 8.94492 22.1468 8.88554 22.1517 8.82492Z" fill="black" />
            <path d="M15.87 9.69848C14.0466 9.95825 13.4454 11.6765 13.5778 13.0174C13.574 13.0546 13.5716 13.0929 13.5679 13.13C13.391 13.1733 13.2648 13.3341 13.2821 13.5197L13.3402 14.1951C13.3563 14.3856 13.5134 14.5229 13.699 14.5316C14.148 16.2436 15.5941 17.8209 17.4287 17.8209C19.2879 17.8209 20.7588 16.2511 21.2189 14.544C21.3934 14.5242 21.5381 14.3906 21.5542 14.2087L21.6111 13.5345C21.6259 13.3576 21.5146 13.2067 21.3563 13.151C21.3525 13.1115 21.3525 13.0706 21.3476 13.0298C21.313 11.4835 21.0433 10.2341 19.8273 9.71827C18.542 9.17397 17.4089 9.16655 16.5788 9.48941C16.5244 9.51168 16.3834 9.69848 16.3277 9.73311C16.272 9.76775 15.9813 9.67126 15.87 9.69848ZM16.5887 11.9709C17.2864 12.6958 19.7531 13.104 20.8367 13.2401C20.8416 13.3094 20.8478 13.3787 20.8478 13.4492C20.8478 15.2293 19.3535 17.2988 17.4274 17.2988C15.5075 17.2988 14.075 15.2664 14.075 13.4492C14.075 13.3638 14.0812 13.2797 14.0874 13.1968C14.8865 12.9049 16.4217 12.2913 16.5887 11.9709Z" fill="black" />
            <path d="M27.9869 14.5313C28.4359 16.2433 29.882 17.8206 31.7166 17.8206C33.5771 17.8206 35.0467 16.2508 35.5068 14.5436C35.6281 14.53 35.7147 14.4509 35.774 14.3507L35.8656 14.3408C35.8866 14.3383 35.9237 14.3247 35.9274 14.2876L35.9856 13.6146C35.9881 13.5689 35.9423 13.5515 35.9373 13.5503L35.899 13.5367C35.9138 13.3598 35.8025 13.2089 35.6429 13.1532C35.6404 13.1136 35.6404 13.0728 35.6355 13.032C35.6009 11.4857 35.3312 10.2363 34.1152 9.72043C32.8299 9.17614 31.6968 9.16871 30.8667 9.49158C30.8123 9.51385 30.6713 9.70064 30.6156 9.73528C30.5612 9.76868 30.2705 9.67343 30.1591 9.6994C28.3357 9.95918 27.7345 11.6774 27.8669 13.0184C27.8632 13.0555 27.8607 13.0938 27.8558 13.1309C27.6789 13.1742 27.5539 13.3351 27.5712 13.5206L27.6294 14.196C27.6455 14.3841 27.8013 14.5239 27.9869 14.5313ZM28.3741 13.1953C29.1732 12.9033 30.7096 12.291 30.8754 11.9694C31.5718 12.6943 34.0397 13.1025 35.1246 13.2386C35.1295 13.3078 35.1357 13.3771 35.1357 13.4476C35.1357 15.2277 33.6414 17.2973 31.7153 17.2973C29.7942 17.2973 28.3617 15.2648 28.3617 13.4476C28.3654 13.3635 28.3691 13.2782 28.3741 13.1953Z" fill="black" />
            <path d="M21.4687 14.791C21.2547 15.485 20.8625 16.1653 20.3467 16.7356L24.5464 16.7381V16.7307L28.8352 16.7282C28.3318 16.1604 27.9483 15.4812 27.738 14.7897C27.5067 14.6957 27.3421 14.4817 27.3199 14.2219L27.2617 13.5465C27.2395 13.2979 27.3557 13.0665 27.5537 12.9305C27.5314 12.693 27.5413 12.448 27.5673 12.2031C27.3174 11.9458 27.0255 11.7639 26.6729 11.7132C26.6036 11.7021 26.5356 11.7182 26.491 11.7367L25.3851 13.2323L24.5452 12.5631L24.5402 12.5668V12.5705L24.539 12.5693L23.7015 13.2397L22.5956 11.7442C22.5511 11.7268 22.483 11.7108 22.415 11.7219C22.0983 11.7664 21.8324 11.9211 21.5986 12.1363C21.6283 12.4122 21.648 12.6905 21.6555 12.9663C21.8361 13.1049 21.9387 13.3276 21.919 13.5614L21.8621 14.2343C21.8398 14.4842 21.6876 14.6908 21.4687 14.791Z" fill="black" />
            <path d="M39.27 25.9652C38.9459 26.7928 38.4115 27.5659 37.7286 28.1374C38.4201 27.5585 38.9545 26.7817 39.27 25.9652Z" fill="black" />
            <path d="M13.6842 19.4018C13.7077 19.4055 13.73 19.4104 13.751 19.4141C13.7597 19.4042 13.7683 19.3943 13.7782 19.3832C13.8883 19.262 13.9601 19.1865 14.0578 19.1482C14.6145 18.9317 15.2441 18.8488 15.9134 18.8859L14.7035 17.2493C14.6417 17.2246 14.5477 17.2035 14.4524 17.2184C13.318 17.378 12.603 18.4777 12.149 19.7555C12.4843 19.5799 12.8529 19.4376 13.2896 19.3745L13.3898 19.3659C13.4714 19.3684 13.5815 19.3844 13.6842 19.4018Z" fill="black" />
            <path d="M18.9947 27.9668L19.0788 28.9503C19.1023 29.2286 19.3312 29.4302 19.6021 29.4426C20.2553 31.9365 22.3632 34.2361 25.0352 34.2361C27.7455 34.2361 29.8881 31.9476 30.5573 29.4599C30.8121 29.4315 31.0224 29.2373 31.0472 28.9713L31.1301 27.9891C31.1536 27.733 30.9915 27.5116 30.7577 27.4324C30.7528 27.3743 30.7515 27.3137 30.7441 27.2555C30.6959 25.0029 30.3037 23.1819 28.5286 22.4311C26.6569 21.6381 25.0067 21.627 23.7969 22.0971C23.7177 22.128 23.5124 22.4014 23.432 22.4521C23.3516 22.5016 22.9273 22.3618 22.7664 22.4001C20.1105 22.7787 19.2335 25.2824 19.4264 27.2345C19.4215 27.2889 19.4165 27.3433 19.4128 27.399C19.1543 27.4621 18.97 27.6959 18.9947 27.9668ZM20.1674 27.4955C21.3302 27.07 23.5693 26.1793 23.8117 25.7105C24.8298 26.7669 28.4234 27.3607 30.0006 27.5586C30.0081 27.66 30.018 27.7615 30.018 27.8629C30.018 30.4582 27.8408 33.4729 25.0352 33.4729C22.2382 33.4729 20.1501 30.5114 20.1501 27.8629C20.1489 27.7392 20.1588 27.618 20.1674 27.4955Z" fill="black" />
            <path d="M18.5793 19.3172L17.414 18.3882L17.4066 18.3944V18.4006L17.4054 18.3993L16.6582 18.9944C17.1431 19.0847 17.6367 19.2121 18.1377 19.4249C19.8003 20.1287 20.0799 21.8965 20.1231 23.5504C20.712 22.8156 21.5556 22.2601 22.7209 22.0931L22.8335 22.0832C22.9312 22.0832 23.0624 22.1043 23.1848 22.1265C23.2071 22.1303 23.2269 22.134 23.2479 22.1377C22.9448 20.177 22.1977 17.4666 20.3644 17.2081C20.2679 17.1932 20.1739 17.2143 20.112 17.2402L18.5793 19.3172Z" fill="black" />
            <path d="M34.6543 17.2081C34.5578 17.1932 34.465 17.2143 34.4031 17.2402L32.868 19.3185L31.7027 18.3895L31.6953 18.3956V18.4018L31.694 18.4006L30.5287 19.3296L28.9923 17.2514C28.9317 17.2266 28.8377 17.2056 28.7424 17.2205C27.1083 17.4505 26.3352 19.6314 25.9727 21.4919C26.8275 21.5439 27.7355 21.7604 28.6521 22.1476C29.2249 22.39 29.6566 22.7339 29.9919 23.1471C29.9807 21.4895 30.8627 19.6809 32.9818 19.3778L33.082 19.3692C33.1624 19.3692 33.2737 19.3865 33.3776 19.4051C33.4011 19.4088 33.4234 19.4137 33.4444 19.4174C33.4531 19.4075 33.463 19.3976 33.4716 19.3865C33.5805 19.2653 33.6523 19.1898 33.7512 19.1515C34.5961 18.8224 35.6105 18.7927 36.6756 19.0488C36.2191 18.0864 35.5746 17.338 34.6543 17.2081Z" fill="black" />
            <path d="M25.012 43.4931L33.8135 43.4857C33.8135 43.4857 33.545 33.937 29.3168 33.342C29.1771 33.3185 29.0385 33.3519 28.9507 33.3878L26.7129 36.4136L25.0144 35.0602L25.0045 35.0677V35.0776L25.0021 35.0751L23.3036 36.4296L21.0658 33.4014C20.9767 33.3655 20.8394 33.3346 20.6996 33.3556C16.4714 33.9494 16.203 43.5005 16.203 43.5005L25.0107 43.5055L25.012 43.4931Z" fill="black" />
            <path d="M34.8477 36.8239L42.0002 36.8189C42.0002 36.8189 41.7825 29.059 38.346 28.5728C38.2322 28.5555 38.1209 28.5803 38.0491 28.6099L36.2294 31.0704L34.8501 29.9682L34.8415 29.9756V29.9843L34.8402 29.9818L33.4609 31.0828L31.6425 28.6236C31.5806 28.5976 31.4842 28.5877 31.3889 28.5926L31.3543 28.9984C31.3283 29.3299 31.113 29.6021 30.8087 29.7159C30.3733 31.1731 29.4072 32.6081 28.091 33.5334C28.0452 33.7597 28.0068 33.9799 27.9697 34.1952L28.7552 33.1338L28.8344 33.1016C28.9235 33.0658 29.1363 33.0002 29.3651 33.0361C31.1266 33.2847 32.2313 34.925 32.9315 36.8338L34.8489 36.8363L34.8477 36.8239Z" fill="black" />
            <path d="M7.99994 36.8314L15.1574 36.8339V36.8216H17.0959C17.7973 34.9227 18.9007 33.2948 20.6561 33.0498C20.8639 33.0189 21.0915 33.077 21.1793 33.1129L21.261 33.1451L22.0304 34.1854C21.9896 33.9368 21.9438 33.6832 21.8894 33.4222C20.666 32.4944 19.7654 31.1151 19.3535 29.7086C19.0318 29.6047 18.7993 29.3226 18.7696 28.9763L18.7374 28.5952C18.709 28.5903 18.6855 28.5767 18.6558 28.5717C18.542 28.5532 18.4306 28.5792 18.3577 28.6076L16.5392 31.0681L15.1599 29.9671L15.1513 29.9745V29.982L15.15 29.9795L13.7707 31.0805L11.9523 28.62C11.8793 28.5928 11.768 28.5668 11.6541 28.5841C8.21766 29.069 7.99994 36.8314 7.99994 36.8314Z" fill="black" />
        </svg>
    );
};

export default Customers;
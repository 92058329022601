import React from "react";

const EasyToUseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
    >
      <rect
        width="50"
        height="50"
        rx="25"
        fill="url(#paint0_linear_1200_852)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.164 19.1016C28.4791 19.1059 28.7949 19.1103 29.1119 19.1135C32.5172 19.1135 35 21.5526 35 24.9209V29.2473C35 32.6156 32.5172 35.0547 29.1119 35.0547C27.7478 35.0837 26.3837 35.0934 25.0098 35.0934C23.6359 35.0934 22.2522 35.0837 20.8881 35.0547C17.4828 35.0547 15 32.6156 15 29.2473V24.9209C15 21.5526 17.4828 19.1135 20.8979 19.1135C22.1835 19.0942 23.4985 19.0748 24.8332 19.0748V18.9103C24.8332 18.2424 24.2738 17.7004 23.6065 17.7004H22.6349C21.526 17.7004 20.6232 16.81 20.6232 15.7259C20.6232 15.3291 20.9568 15 21.3592 15C21.7713 15 22.0952 15.3291 22.0952 15.7259C22.0952 16.0163 22.3405 16.2486 22.6349 16.2486H23.6065C25.0883 16.2583 26.2954 17.4488 26.3052 18.9006V19.0845C26.9238 19.0845 27.5425 19.093 28.164 19.1016ZM23.8519 27.8052H22.8215V28.8312C22.8215 29.228 22.4879 29.5571 22.0855 29.5571C21.6733 29.5571 21.3495 29.228 21.3495 28.8312V27.8052H20.3093C19.9069 27.8052 19.5732 27.4858 19.5732 27.0793C19.5732 26.6825 19.9069 26.3534 20.3093 26.3534H21.3495V25.3371C21.3495 24.9403 21.6733 24.6112 22.0855 24.6112C22.4879 24.6112 22.8215 24.9403 22.8215 25.3371V26.3534H23.8519C24.2543 26.3534 24.588 26.6825 24.588 27.0793C24.588 27.4858 24.2543 27.8052 23.8519 27.8052ZM28.0231 26.1693H28.1213C28.5236 26.1693 28.8573 25.8499 28.8573 25.4435C28.8573 25.0467 28.5236 24.7177 28.1213 24.7177H28.0231C27.611 24.7177 27.2871 25.0467 27.2871 25.4435C27.2871 25.8499 27.611 26.1693 28.0231 26.1693ZM29.7009 29.4985H29.799C30.2014 29.4985 30.535 29.1791 30.535 28.7726C30.535 28.3758 30.2014 28.0468 29.799 28.0468H29.7009C29.2877 28.0468 28.9648 28.3758 28.9648 28.7726C28.9648 29.1791 29.2877 29.4985 29.7009 29.4985Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1200_852"
          x1="49.0468"
          y1="6.15268"
          x2="0.953772"
          y2="43.8473"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B0008F" />
          <stop offset="1" stopColor="#0045E2" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EasyToUseIcon;

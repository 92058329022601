import React from "react";

const LineBG = () => {
  return (
    <svg
      width="750"
      height="2658"
      viewBox="0 0 933 2658"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M240.779 147.31C299.278 81.8101 418.495 64.2821 422.279 49.3094"
        stroke="#848996"
        strokeWidth="3"
        strokeLinecap="square"
        strokeDasharray="12.6 12.6"
      />
      <path
        d="M97.7792 369.523C43.1662 391.525 9.77861 482.023 24.2653 558.614C42.9473 657.384 134.779 723.023 329.279 736.023C471.779 745.547 617.779 711.057 765.279 805.523C841.511 854.346 906.476 1002.51 921.779 1060.02"
        stroke="#848996"
        strokeWidth="3"
        strokeDasharray="12.6 12.6"
      />
      <path
        d="M921.779 1270.02C759.469 1464.3 676.481 1474.52 509.81 1451.52C439.658 1441.84 308.156 1408.16 213.779 1513.02C159.779 1573.02 125.148 1640.02 105.779 1736.02"
        stroke="#848996"
        strokeWidth="3"
        strokeDasharray="12.6 12.6"
      />
      <path
        d="M817.78 2598.52C826.78 2304.52 509.78 2262.52 453.44 2260.27C388.027 2257.66 183.538 2260.11 143.817 2109.54C121.103 2023.44 152.722 1938.4 162.449 1833.88"
        stroke="#848996"
        strokeWidth="3"
        strokeDasharray="12.6 12.6"
      />
    </svg>
  );
};

export default LineBG;

import React from 'react';

const MailBlack = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="19" viewBox="0 0 22 19" fill="none">
            <g clip-path="url(#clip0_937_10227)">
                <path opacity="0.4" d="M21.2063 13.6255C21.2063 16.5631 18.8315 18.9427 15.8737 18.9532H15.8631H5.35695C2.40972 18.9532 0.00317383 16.5841 0.00317383 13.6465V13.636C0.00317383 13.636 0.00953475 8.97571 0.018016 6.6319C0.0190762 6.19177 0.52795 5.94539 0.874621 6.21914C3.39355 8.20391 7.89814 11.8228 7.95434 11.8702C8.70704 12.4693 9.66117 12.8073 10.6365 12.8073C11.6119 12.8073 12.566 12.4693 13.3187 11.8586C13.3749 11.8218 17.7788 8.31131 20.3359 6.29391C20.6837 6.01909 21.1946 6.26547 21.1957 6.70455C21.2063 9.03046 21.2063 13.6255 21.2063 13.6255Z" fill="black" />
                <path d="M20.6497 2.81552C19.7316 1.09715 17.925 0 15.9362 0H5.35588C3.36702 0 1.56052 1.09715 0.642427 2.81552C0.436758 3.19985 0.534291 3.67892 0.876721 3.95058L8.74837 10.2039C9.29965 10.6462 9.96753 10.8662 10.6355 10.8662C10.6397 10.8662 10.6428 10.8662 10.6461 10.8662C10.6492 10.8662 10.6534 10.8662 10.6566 10.8662C11.3245 10.8662 11.9925 10.6462 12.5437 10.2039L20.4153 3.95058C20.7578 3.67892 20.8553 3.19985 20.6497 2.81552Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_937_10227">
                    <rect width="22" height="19" fill="black" transform="translate(0.00317383)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default MailBlack;
import React from "react";

const MinimalRisk = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 70 70"
      fill="none"
    >
      <path
        d="M26.2498 55.4167C26.2498 57.6042 25.6373 59.675 24.5582 61.425C22.5457 64.8083 18.8415 67.0833 14.5832 67.0833C10.3248 67.0833 6.62068 64.8083 4.60818 61.425C3.52901 59.675 2.9165 57.6042 2.9165 55.4167C2.9165 48.9708 8.13734 43.75 14.5832 43.75C21.029 43.75 26.2498 48.9708 26.2498 55.4167Z"
        stroke="black"
        strokeWidth="4"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0371 55.4152L12.9246 58.3026L19.1371 52.5569"
        stroke="black"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.7748 20.5623C51.0748 20.4457 50.3456 20.4165 49.5873 20.4165H20.4206C19.6039 20.4165 18.8164 20.4749 18.0581 20.5915C18.4664 19.7749 19.0498 19.0166 19.7498 18.3166L29.2289 8.80825C33.2248 4.84159 39.6998 4.84159 43.6956 8.80825L48.7998 13.9706C50.6664 15.8081 51.6581 18.1415 51.7748 20.5623Z"
        stroke="black"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.1668 35.0001V49.5834C64.1668 58.3334 58.3335 64.1667 49.5835 64.1667H22.2543C23.1585 63.4084 23.946 62.4751 24.5585 61.4251C25.6377 59.6751 26.2502 57.6042 26.2502 55.4167C26.2502 48.9709 21.0293 43.7501 14.5835 43.7501C11.0835 43.7501 7.96266 45.2959 5.8335 47.7167V35.0001C5.8335 27.0667 10.6168 21.5251 18.0543 20.5917C18.8127 20.4751 19.6002 20.4167 20.4168 20.4167H49.5835C50.3418 20.4167 51.071 20.4459 51.771 20.5625C59.296 21.4375 64.1668 27.0084 64.1668 35.0001Z"
        stroke="black"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.1668 36.4583H55.4168C52.2085 36.4583 49.5835 39.0833 49.5835 42.2916C49.5835 45.4999 52.2085 48.1249 55.4168 48.1249H64.1668"
        stroke="black"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MinimalRisk;
